<template>
  <v-container>
    <v-card class="elevation-2 mx-auto" :loading="loading" max-width="600">
      <v-card-title>Edit Event</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <form>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="py-4">
              <v-text-field
                label="Title"
                v-model="form_data.title"
                :error-messages="form_error.title"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <p class="font-weight-bold mt-2">Description</p>
              <vue-editor v-model="form_data.description">
              </vue-editor>
              <span v-if="form_error.redirect_url" :style="{ color: 'red' }">{{ form_error.description }}</span>
            </v-col>
            <v-col v-col cols="12" md="12" sm="12" class="py-0 mt-5">
              <v-radio-group v-model="date_type" row>
                <v-radio
                  label="Single Date"
                  value="single_date"
                />
                <v-radio
                  label="Date Range"
                  value="multiple"
                />
                <v-radio
                  label="No Date"
                  value="no_date"
                />
              </v-radio-group>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                v-if="date_type == 'multiple'"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Date Range"
                    readonly
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    :error="form_error.datetime != null"
                    :error-messages="form_error.datetime"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  :show-current="false"
                  scrollable
                  range
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-datetime-picker
                label="Datetime"
                v-model="form_data.datetime"
                v-if="date_type == 'single_date'"
                :error-messages="form_error.datetime"
                :text-field-props="textFieldProps"
                :date-picker-props="dateProps"
                :time-picker-props="timeProps"
                outlined
              >
                <template slot="actions" slot-scope="{ parent }">
                  <v-btn color="error lighten-1" @click="parent.clearHandler">Clear</v-btn>
                  <v-btn color="success darken-1" @click="parent.okHandler">Done</v-btn>
                </template>
                <div slot="dateIcon" color="primary" dark>
                  <v-icon>mdi-calendar</v-icon>
                </div>
                <div slot="timeIcon">
                  <v-icon>mdi-clock</v-icon>
                </div>
              </v-datetime-picker>
              <p v-if="form_error.datetime != null && date_type == 'single_date'" class="red--text mb-5">{{ form_error.datetime }}</p>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field
                label="Timezone"
                v-model="form_data.timezone"
                :error-messages="form_error.timezone"
                v-if="date_type != 'no_date'"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field
                label="Redirect Url"
                v-model="form_data.redirect_url"
                :error-messages="form_error.redirect_url"
                placeholder="https://www.example.com"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0" v-show="form_data.redirect_url">
              <v-text-field
                label="Button Label"
                v-model="form_data.button_label"
                :error-messages="form_error.button_label"
                placeholder="Info"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-file-input
                v-model="form_data.event_image"
                accept="image/png, image/jpeg, image/jpg"
                :error-messages="form_error.event_image"
                prepend-icon="mdi-camera"
                label="Image"
                outlined
                dense
              ></v-file-input>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0 mb-3">
              <v-switch
                v-model="form_data.is_active"
                dense
                label="Active"
                class="mt-0"
              ></v-switch>
            </v-col>
          </v-row>
          <v-btn
            class="mr-4"
            color="secondary"
            @click="upadteEvent"
            :loading="loading"
            :disabled="loading"
          >Update</v-btn>
        </form>
      </v-card-text>
    </v-card>
    <v-btn small color="secondary" fixed fab bottom left to="/events">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
import moment from "moment";
import { VueEditor } from "vue2-editor";
import { mapMutations } from "vuex";
// import moment from 'moment'
export default {
  name: "EditObject",
  components: {
      VueEditor
  },
  watch: {
    date_type: function() {
      this.date_type == 'single_date' ? this.date = null : this.form_data.datetime = null;
    },
    date: {
      handler: function() {
        if(this.date.length == 2) {
          if(moment(this.date[0]).isAfter(this.date[1])) {
            let temp = this.date[0];
            this.date[0] = this.date[1];
            this.date[1] = temp;
          }
        }
      },
      deep: true
    }
  },
  data() {
    return {
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link"],
      ],
      textFieldProps: {
        outlined: "",
        dense: "",
      },
      dateProps: {
        headerColor: "primary",
        color: "secondary",
        class: "rounded-0",
      },
      timeProps: {
        headerColor: "primary",
        color: "secondary",
        class: "rounded-0",
      },
      pages: [],
      positions: [],
      form_data: {
        redirect_url: ""
      },
      form_error: {},
      loading: false,
      data: {},
      menu: false,
      date: [],
      date_type: 'single_date'
    };
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    getEventDetails() {
      this.loading = true;
      let _self = this;      
      this.$axios.get(`admin/event/details/${this.$route.params.id}`)
        .then((res) => {
          _self.form_error = {};
          if (res.status) {
            let data = res.data.data;
            for (let key in data) {
              _self.form_data[key] = data[key];
            }
            _self.date = data.multiple_datetime ? data.multiple_datetime.split(',') : [];
            _self.form_data.datetime = ![null, undefined, "", "null"].includes(data.datetime) ? new Date(data.datetime) : "";
            _self.date_type = _self.form_data.datetime && ![null, undefined, "", "null"].includes(_self.form_data.datetime) ? 'single_date' : (![null, undefined, "", "null"].includes(data.multiple_datetime) && _self.date.length > 0 ? 'multiple' : 'no_date');
            var div = document.createElement("div");
            div.innerHTML = _self.form_data.description;
            _self.form_data.description = div.innerHTML;
          } else {
            let errors = res.error;
            for (let key in errors) {
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    upadteEvent() {
      let _self = this;
      this.loading = "secondary";

      let formData = { ...this.form_data }
      formData["date_type"] = this.date_type;
      formData["multiple_date"] = this.date;

      if(formData.datetime) {
        delete formData.datetime;

        formData.datetime = moment(this.form_data.datetime).format('YYYY-MM-DD HH:mm');
      } 

      let serverData = JSON.parse(JSON.stringify(formData));
      let req_data = new FormData();
      for (let key in serverData) {
        req_data.append(key, serverData[key]);
      }

      if(this.form_data.event_image) {
        req_data.append('event_image', this.form_data.event_image);
      }
    
      this.$axios.put(`admin/event/edit/${this.$route.params.id}`, req_data)
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 2000,
            });
            _self.$router.push("/events");
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
  },
  mounted: function () {
    this.getEventDetails();    
  },
};
</script>